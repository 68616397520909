* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
body {
  font-family: univia-pro, sans-serif !important;
  scrollbar-width: none;

/* Hide scrollbar thumb for Firefox */
scrollbar-color: transparent transparent;
}
body::-webkit-scrollbar {
  width: 0;
}
